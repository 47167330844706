var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"paginate"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentPage > 1),expression:"currentPage > 1"}],staticClass:"paginate prev",on:{"click":function($event){_vm.currentPage--}}},[_c('i',{staticClass:"fas fa-chevron-left"})]),_vm._l((_vm.range(0, _vm.totalPages).slice(
      _vm.totalPages - _vm.currentPage < 4 && _vm.totalPages - _vm.currentPage > 5
        ? _vm.totalPages - 4
        : _vm.currentPage > 1
        ? _vm.currentPage - 1
        : 1,
      _vm.currentPage <= 1 ? _vm.currentPage + 5 : _vm.currentPage + 4
    )),function(pageIndex){return _c('button',{key:("current-page-" + pageIndex),staticClass:"paginate",class:{ active: pageIndex == _vm.currentPage },on:{"click":function($event){_vm.currentPage = pageIndex}}},[_vm._v(" "+_vm._s(pageIndex)+" ")])}),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentPage < _vm.totalPages),expression:"currentPage < totalPages"}],staticClass:"paginate next",on:{"click":function($event){_vm.currentPage++}}},[_c('i',{staticClass:"fas fa-chevron-right"})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }